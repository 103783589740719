class CustomSongClient {
  constructor(el, sendFlow) {
    this.el = el;
    this.sendFlow = sendFlow;
    this.init();
  }

  init() {
    this.client = new SongfinchClient(this.getCustomSongConfig());
    this.client.init();
    const addCustomSongBtns = document.querySelectorAll("button[data-controls='add-custom-song']");
    addCustomSongBtns.forEach((addCustomSongBtn) => {
      addCustomSongBtn?.addEventListener("click", () => {
        this.client.open();
      });
    });
    const removeCustomSongBtn = this.sendFlow.forms.get("email-send")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeSharedCustomSong = window.app_forms.collection.get("gift-options-form")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeCustomSongDialog = window.app_dialogs.collection.get("custom-song-info")?.el.querySelector("button[data-controls='remove-custom-song']");
    const removeButtons = [removeCustomSongBtn, removeCustomSongDialog, removeSharedCustomSong];

    removeButtons.forEach((removeButton) => {
      removeButton?.addEventListener("click", (event) => {
        let removeButton = event.target;
        let form = this.sendFlow.forms.get(removeButton.getAttribute("data-form"));
        if (this.sendFlow.order.features_used.includes("used_songfinch_gift")) {
          this.updateMiscusageWithCustomSong("deletedsong|");
        }
        this.sendFlow.removeGiftAttached();
        this.sendFlow.order.personalizations.gift = {};
        this.sendFlow.order.gift = {};
        let index = this.sendFlow.order.features_used.indexOf("used_songfinch_gift");
        this.sendFlow.order.features_used.splice(index, 1);
        form.fields.get("custom-song").setValue(false, true);
        this.sendFlow.hydrateDeliveryDetails();
        this.sendFlow.hydrateGiftOptionsSubmit();
        this.sendFlow.saveFlow();
      });
    });

    this.client.on("song-customization-finished", (songfinchResponse) => {
      this.sendFlow.order.personalizations.gift = songfinchResponse;
      this.sendFlow.order.gift = songfinchResponse;
      this.sendFlow.order.features_used.push("used_songfinch_gift");
      this.sendFlow.addGiftAttached("custom-song", songfinchResponse);
      if (this.sendFlow.order.current_step == "personalize") {
        this.sendFlow.forms.get("gift-options-form").fields.get("custom-song").setValue(true, true);
        window.app_images?.loadImages(window.app_dialogs.collection.get("gift-options").el);
        this.sendFlow.hydrateGiftOptionsSubmit();
      }
      else {
        this.sendFlow.forms.get("email-send").fields.get("custom-song").setValue(true, true);
        this.sendFlow.hydrateDeliveryDetails();
      }
      this.updateMiscusageWithCustomSong("selectedsong|");
      this.sendFlow.saveFlow();
    });
  }

  getCustomSongConfig() {
    return {
      service: {
        clientId: app_env.client_id,
        baseUrl: app_env.apihost,
        collectionId: app_env.product_config?.custom_song?.collection_id || [],
        customerEmail: app_env.customer?.email || "",
        extraParams: {
          jwt_auth: app_env.product_config?.custom_song?.jwt_auth,
          item_number: app_env.ecard_order.id,
          customer_number: app_env.customer?.number || 0
        }
      },
      ui: {
        strings: {
          song_price: `$${app_env.checkout_price}`,
        }
      },
    }
  }

  async updateMiscusageWithCustomSong(navigation) {
    const bodyReq = {
      customer_number: app_env.customer.number,
      nav_type: `send/${app_env.ecard_order.id}`,
      navigation: navigation
    }
    try {
      await fetch(`${app_env.apihost}/simple/miscusage?client_id=${app_env.client_id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST",
        body: new URLSearchParams(bodyReq).toString(),
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default CustomSongClient;