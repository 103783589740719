import Client from "../../base/Client";
import Render from "../../base/Render";
import Utility from "../../base/Utility";
import DynamicHeight from "../../utilities/DynamicHeight";

class Filter {
  constructor(el) {
    this.el = el;
    this.type = el.getAttribute('data-filter-type') || 'browse';
    this.isHidden = () => (Client.isHidden(this.el));
    this.form = window.app_forms?.collection.get(el.id) || null;
    this.dialog = window.app_dialogs?.collection.get('aside') || null;
    this.blocks = el.querySelectorAll('.filters__block');
    this.activeBlock = el.querySelector('.filters__block.--active-filters');
    this.activeValues = new Map();
    this.searchField = () => (this.form ? this.form.fields.get('q') : null)
    this.filters = new Map();
    this.config = {
      exclusiveTraits: true
    };
    this.response = null;
    this.allFilters = new Map();
    this.activeFilterTemplate = el.querySelector('.active-filter-template') || null;
    this.activeList = el.querySelector('.filters__actives') || null;
    this.filtersCount = 0;
    this.containersOpened = new Map();
    this.filterStatus = el.querySelector('.filter__status') || null;
    this.package = {};
    this.queryUrl = new URL(window.location.href);
    this.isStateChange = false;
    this.maxFilters = el.querySelector('input[name="max-filters"]')?.value || 20;
    this.maxFiltersShown = el.querySelector('input[name="max-filters-per-block"]')?.value || 6;
    this.maxTrigger = el.querySelector('input[name="max-trigger"]')?.value || 8;
    this.browseFilter = el.querySelector('input[name="browse_filter"]')?.value || '';
    this.browseValue = el.querySelector('input[name="browse_value"]')?.value || '';
    this.resultsPerPage = Number(el.querySelector('input[name="num_results_per_page"]')?.value || 30);
    this.imgsLoaded = [];
    this.originalPathname = el.querySelector('input[name="original-pathname"]') || null;
    this.lastTriggerEvent = null;
    this.loadMore = document.querySelector('.load-more') || null;
    this.loadingEl = null;
    this.isLanding = el.querySelector('input[name="is-landing"]') || false;
    this.loadingTemplate = document.querySelector('.global-templates .loading-template') || null;
    this.showResultsBtn = el.parentElement.querySelector('.filters__show-results-holder') || null;
    this.init();
  }

  init() {
    this.initBlocks();
    this.initFilters();
    this.initExternal();
    this.renderLoading();
    this.updateStatus(false);
    this.updateUrl(true, true);
    if (this.form && this.searchField()) {
      this.form.overrideSubmitHandler = this.searchSubmit.bind(this)
    }
    if (this.dialog) {
      window.app_listeners?.add('isDesktop', `close-aside-${this.el.id}`, () => {
        window.app_dialogs?.deactivate(this.dialog.name)
      });
    }
    window.app_listeners?.add('click', `click-${this.el.id}`, this.clickHandler.bind(this));
    window.app_listeners?.add('popstate', `popstate-${this.el.id}`, this.stateHandler.bind(this));
    window.app_listeners?.add('pageshow', `pageshow-${this.el.id}`, this.pageshowHandler.bind(this));
    this.el.classList.add('--filters-init');
  }

  renderLoading() {
    if (!this.loadingTemplate) return
    const fragment = this.loadingTemplate.content.cloneNode(true);
    const productLoopWrapper = document.querySelector('.main-content-wrapper');
    if (!productLoopWrapper) return
    productLoopWrapper.appendChild(fragment);
    this.loadingEl = productLoopWrapper.querySelector('.form__loading') || null;
    this.loadingEl && window.app_accessibility?.ariaHiddenElInit(this.loadingEl);
  }


  initExternal() {
    if (!window.app_forms?.collection.has('ag-sorting')) return
    const extForm = window.app_forms?.collection.get('ag-sorting');
    const field = extForm.fields.get('sort');
    const sort = this.form.el.querySelector('input[name="sort_by"]');
    field.onSelect = () => {
      sort.value = field.value
      this.filter();
    }
  }

  initBlocks() {
    if (!this.blocks || this.blocks.length == 0) return
    this.blocks.forEach(this.initBlock.bind(this));
  }

  initFilters() {
    this.filtersCount = 0;
    this.filters = new Map();
    this.form.fields.forEach((field, key) => {
      if (field.hasOwnProperty('onCheck')) {
        field.onCheck = el => { this.addTrait(el.name, el.value); }
        field.onUncheck = el => { this.removeTrait(el.name, el.value); }
      }
    })
    this.el.querySelectorAll('.filter-trigger[checked]')?.forEach(el => this.addTrait(el.name, el.value, false));
    this.removeStrays();
  }

  removeStrays() {
    let removed = false;
    const filters = this.activeList.querySelectorAll('.filter-remove');
    filters.forEach(filter => {
      if (filter.classList.contains('--remains')) {
        filter.classList.remove('--remains');
      } else {
        removed = true;
        filter.parentElement.remove();
      }
    })
    if (removed) {
      this.updateUrl(true, true, true);
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;

    if (!this.el.contains(target)) {
      if (target.classList.contains('filter-bar-btn') && target.classList.contains('--clear')) {
        this.reset()
      }
      return
    }
    if (target.classList.contains('filter-remove')) {
      const trait = target.value;
      const filter = target.getAttribute('data-filter');
      if (this.form && window.app_page?.wasKeyboardEvent) {
        this.form.returnFocusEl = target;
      }
      this.removeTrait(filter, trait);
    } else if (target.classList.contains('filters__reset')) {
      this.reset()
    }
  }

  searchSubmit() {
    this.reset();
  }

  loadFromState(params, refilter = true) {
    // Clear previousely selected filters
    const els = this.el.querySelectorAll('.filter-trigger:checked, .filter-trigger[checked]');
    if (els || els.length != 0) {
      els.forEach(el => {
        let filterFound = false;
        let traitFound = false;
        for (const filter in params["filters"]) {
          if (filter == el.name) {
            filterFound = true;
          }
          const traits = params[filter];
          if (traits instanceof Array) {
            traits.forEach(trait => {
              if (trait == el.value) {
                traitFound = true;
              }
            })
          }
        }
        if (!filterFound || !traitFound) {
          el.checked = false;
          this.removeTrait(el.name, el.value, false);
        }
      });
    }

    if (this.searchField() && params.hasOwnProperty('q')) {
      this.searchField().el.value = params.q;
    }

    // Add current filters
    for (const filter in params) {
      if (['q', 'sort'].includes(filter)) continue
      const traits = params[filter];
      traits.forEach(trait => {
        this.addTrait(filter, trait, false);
      })
    }
    // Update filtering
    if (refilter) {
      this.filter();
    }
  }

  pageshowHandler(e) {
    if (e.persisted) {
      this.form?.el.classList.remove('--loading');
      document.querySelector('.main-content-wrapper')?.classList.remove('--loading');
      this.loadFromState(this.package.params, false);
    }
  }

  stateHandler(e) {
    if (!(e instanceof PopStateEvent)) return
    if (!e.state?.hasOwnProperty('params')) return
    this.isStateChange = true;
    this.loadFromState(e.state.params)
  }

  showContainer(key) {
    const container = this.el.querySelector(`.filters__block-holder.--${key}`);
    if (!container) return
    if (container.firstElementChild.getAttribute('aria-hidden') == 'false') return
    container.firstElementChild.classList.add('--transition');
    container.firstElementChild.setAttribute('aria-hidden', false);
    container.classList.remove('--hidden');
    setTimeout(() => {
      container.firstElementChild.classList.remove('--transition');
    }, 300);
  }

  hideContainer(key) {
    const container = this.el.querySelector(`.filters__block-holder.--${key}`);
    if (!container) return
    if (container.firstElementChild.getAttribute('aria-hidden') == 'true') return
    container.firstElementChild.classList.add('--transition');
    container.firstElementChild.setAttribute('aria-hidden', true);
    setTimeout(() => container.classList.add('--hidden'), 0);
    setTimeout(() => {
      container.firstElementChild.classList.remove('--transition');
    }, 300);
  }

  addTrait(filter, trait, refilter = true) {
    const el = this.el.querySelector(`.filter-trigger[name="${filter}"][value="${trait}"]`);
    if (!el) return

    if (!this.filters.has(filter)) {
      this.filters.set(filter, new Map());
    }
    setTimeout(() => { el.checked = true }, 10)
    if (this.filters.get(filter).has(trait)) return
    this.filters.get(filter).set(trait, el);
    this.filtersCount++;
    this.activeValues.set(trait, filter);
    let option = el.getAttribute("data-option-display");
    const activeTrait = this.renderActiveTrait(filter, trait, option);
    if (this.filtersCount != 0) {
      this.showContainer('active-filters');
      this.el.classList.remove('--no-active-filters');
    }
    if (this.config.exclusiveTraits) {
      this.filters.get(filter)?.forEach((el, activeTrait) => {
        if (activeTrait == trait) return
        this.removeTrait(filter, activeTrait, false);
      })
    }
    if (this.filtersCount >= 5) {
      this.el.classList.add('--filters-overload');
    }
    if (refilter) {
      this.filter();
    } else {
      activeTrait.classList.add('--remains');
    }
  }

  removeTrait(filter, trait, refilter = true, delay = 0) {
    if (!this.filters.has(filter)) return
    if (!this.filters.get(filter).has(trait)) return
    if (this.filters.get(filter).get(trait).hasAttribute('checked')) {
      this.filters.get(filter).get(trait).checked = false
      this.filters.get(filter).get(trait).removeAttribute('checked');
    }
    this.filters.get(filter).delete(trait);
    this.filtersCount--;
    if (this.filters.get(filter).size == 0) {
      this.filters.delete(filter);
    }
    if (this.activeValues.has(trait)) {
      this.activeValues.delete(trait);
    }
    this.removeActiveTrait(filter, trait, delay);
    if (this.filtersCount == 0) {
      this.el.classList.add('--no-active-filters');
      this.hideContainer('active-filters');
    }
    if (refilter) {
      setTimeout(() => {
        this.filter();
      }, 0)
    }
  }

  getOption(filter, trait) {
    if (!this.response) return null
    return this.getFacet(filter)?.options?.find(option => option.value == trait)
  }

  getFacet(filter) {
    if (!this.response) return null
    return this.response.facets?.find(facet => facet.name == filter);
  }

  renderActiveTrait(filter, trait, option) {
    if (!this.activeFilterTemplate || !this.activeList) return
    let el = this.activeList.querySelector(`.filter-remove[data-filter="${filter}"][value="${trait}"]`);
    if (!el) {
      let rendered = Render.interpolateTemplate(this.activeFilterTemplate, {
        filter: filter,
        label: trait,
        display: option,
      })
      this.activeList.appendChild(rendered);
      el = this.activeList.querySelector(`.filter-remove[data-filter="${filter}"][value="${trait}"]`);
    }
    return el;
  }

  removeActiveTrait(filter, trait, delay = 0) {
    if (!this.activeList) return
    const el = this.activeList.querySelector(`.filter-remove[data-filter="${filter}"][value="${trait}"]`);
    if (!el) return
    if (delay != 0) {
      el.parentElement.style.top = el.parentElement.offsetTop + 'px';
      el.parentElement.style.left = el.parentElement.offsetLeft + 'px';
      setTimeout(() => {
        el.parentElement.style.position = 'absolute';
      }, 0);
      setTimeout(() => el.parentElement.remove(), delay);
    } else {
      el.parentElement.remove();
    }
  }

  composeQuery(full = true, save = true) {
    const localPackage = {
      path: {},
      params: {
        filters: {}
      },
    };
    let ordered_path = '';
    const originalPathname = this.originalPathname?.value || null;
    let order_string = "[product_types]/[occasions]/[holidays]/[recipients]/[tones]/[collections]/[custom_features]/[themes]";
    if (window.app_env?.filter_config?.url_pattern) {
      order_string = app_env.filter_config.url_pattern;
      if (order_string.startsWith("/")) {
        order_string = order_string.substring(1)
      }
    }
    let order = order_string.replaceAll('[', '').replaceAll('_', ' ').replaceAll(']', '').capitalize().split('/');
    if (this.searchField() && this.searchField().el.value.trim() !== '') {
      localPackage.params['q'] = this.searchField().el.value;
    }
    if (full) {
      const sortInput = this.form?.el.querySelector('input[name="sort_by"]');
      if (sortInput && !['relevance', ''].includes(sortInput.value.trim())) {
        localPackage.params['sort'] = sortInput.value;
      }
      this.filters.forEach((filter, filterName) => {
        localPackage.params["filters"][filterName] = [];
        localPackage.path[filterName] = [];
        filter.forEach((trait, traitName) => {
          localPackage.params["filters"][filterName].push(traitName);
          if (trait.hasAttribute('data-path') && trait.getAttribute('data-path') != '') {
            localPackage.path[filterName].push(trait.getAttribute('data-path'));
          }
        })
        localPackage.params["filters"][filterName].sort();
        localPackage.path[filterName].sort();
      })
      const ordered_params = Object.fromEntries(Object.entries(localPackage.path).sort(([a], [b]) => order.indexOf(a) - order.indexOf(b)));
      let path_array = []
      Object.values(ordered_params).forEach((values) => {
        values.forEach(value => {
          path_array.push(value);
        })
      })
      if (path_array.length != 0) {
        ordered_path = path_array.join('');
      }
    }
    if (save) {
      this.package = localPackage;
    }
    let pathname = originalPathname ? originalPathname : window.location.pathname
    let url = window.location.origin + pathname;
    let seo_url = url;

    if (Object.keys(localPackage.params).length !== 0) {
      const queryParams = {...localPackage.params};
      delete queryParams["filters"];
      let filters = [];
      Object.entries(localPackage.params.filters).forEach(entry => {
        filters.push(`${entry[0]}:${entry[1].join("-or-")}`)
      })
      queryParams["filters"] = filters.join("|");
      const searchParams = new URLSearchParams(queryParams).toString();
      seo_url = url + ordered_path;
      url += "?" + searchParams;
    }
    return { localPackage, url, seo_url };
  }

  updateUrl(full = true, initialization = false, removal = false) {
    const { localPackage, url } = this.composeQuery(full);
    window.app_storage?.set("state", localPackage);
    if (initialization) {
      window.history.replaceState(localPackage, 'unused', removal ? url : window.location.href);
    } else {
      window.history.pushState(localPackage, 'unused', url);
    }
  }

  reset(refilter = true) {
    const map = this.filters;
    map.forEach((filter, filterName) => {
      filter.forEach((trait, traitName) => {
        this.removeTrait(filterName, traitName, false, 300);
      })
    })
    if (refilter) {
      this.filter();
    }
  }

  updateStatus(announce = true) {
    if (this.blocks) {
      this.blocks.forEach(this.updateBlock.bind(this));
    }
    if (this.filterStatus) {
      if (announce) {
        this.filterStatus.setAttribute('role', 'status');
      } else {
        this.filterStatus.removeAttribute('role');
      }
      this.filterStatus.innerHTML = '';
      if (this.filtersCount == 0) {
        this.filterStatus.textContent = `All filters cleared`;
      } else {
        let traits = '';
        let filters = '';
        let countFilters = 1;
        this.filters.forEach((filter, filterName) => {
          let countTraits = 1;
          let localTraits = '';

          filter.forEach((trait, traitName) => {
            if (countTraits == 1 && countFilters == 1) {
              localTraits += traitName;
            } else if (countTraits == filter.size && countFilters == this.filters.size) {
              localTraits += ' and ' + traitName;
            } else {
              localTraits += ', ' + traitName;
            }
            countTraits++
          })

          traits += `${localTraits}`;
          if (countFilters == 1) {
            filters += `${filterName}`;
          } else if (countFilters == this.filters.size) {
            filters += ` and ${filterName}`;
          } else {
            filters += `, ${filterName}`;
          }

          countFilters++
        })
        this.filterStatus.textContent = `Filtering by ${this.filtersCount} ${this.filtersCount == 1 ? 'trait' : 'traits'} from ${filters}: ${traits}`
      }
    }
  }

  filter() {
    if (this.form) {
      const { localPackage, url } = this.composeQuery(true, false);
      let getUrl = new URL(url);
      getUrl.pathname = "/fetch-request" + getUrl.pathname
      this.form.submitHandler(null, getUrl.href, { method: 'GET' }, true);
      this.loading();
      this.form.onResponse = this.handleResponse.bind(this);
      this.form.submitComplete = (form) => { this.updateStatus() };
    }
  }

  handleResponse(form) {
    if (!form.lastRequest.response?.status || form.lastRequest.response.status != 'success') {
      form.persistLoading = true;
      window.location.href = this.queryUrl.href;
      return
    } else if (form.lastRequest.response?.data?.redirect_url) {
      form.persistLoading = true;
      window.location.href = window.location.origin + new URL(form.lastRequest.response.data.redirect_url).pathname;
      return
    } else if (this.isLanding) {
      form.persistLoading = true;
      window.location.href = this.queryUrl.href;
      return
    }
    if (!this.isStateChange) {
      this.updateUrl();
    } else {
      this.composeQuery();
      this.isStateChange = false;
    }
    this.response = form.lastRequest.response.data;
    if (!this.response) {
      this.complete();
      return
    }
    this.updateConstructorData();
    this.hydrateFilters();
    this.hydrateSearch();
    this.hydrateProducts();
    this.hydrateCount();
    this.hydrateShowResults();
    this.hydrateFilterBar();
    this.hydrateTitle(this.filtersCount);
    this.complete();
  }

  updateConstructorData() {
    let excludedKeys = ["facets", "results", "result_id"];
    let constructorData = {};
    for (let key in this.response) {
      if (!excludedKeys.includes(key)) {
        constructorData[key] = this.response[key];
      }
    }
    window.app_services?.track("product-listing-filtrated", this.response);
    Utility.updateContent({
      "cnstrc-data": JSON.stringify({
        request: constructorData,
        result_id: this.response["result_id"]
      }),
      product_listing: {
        "data-cnstrc-num-results": this.response.total_results_count,
        "data-cnstrc-filter-value": this.response.browse_filter_value,
        "data-cnstrc-filter-name": this.response.browse_filter_name
      }
    })
  }

  hydrateShowResults() {
    if (!this.showResultsBtn || !this.response) return
    if (this.filtersCount == 0 || this.response.total_results_count == 0) {
      this.showResultsBtn.firstElementChild.textContent = `Close Filters`;
    } else if (this.response.total_results_count == 1) {
      this.showResultsBtn.firstElementChild.textContent = `Show 1 result`;
    } else {
      this.showResultsBtn.firstElementChild.textContent = `Show ${this.response.total_results_count} results`;
    }
  }

  hydrateFilterBar() {
    const filterBar = document.querySelector('.filter-bar');
    const filterBarCount = document.querySelector('.filter-bar-btn__count');
    if (!filterBar || !filterBarCount) return
    if (this.filtersCount == 0) {
      filterBar.classList.remove('--has-filters');
      filterBarCount.textContent = '';
    } else {
      filterBarCount.textContent = this.filtersCount;
      filterBar.classList.add('--has-filters');
    }
  }

  hydrateSearch() {
    if (!this.searchField()) return
    // TODO revisit this if we would like to add Dynamic Search
    let searchTerm = this.response.term || this.searchField().el.value.trim();
    this.searchField().el.value = searchTerm;
    const title = document.querySelector('h1');
    if (!title) return
    let string = null;
    if (searchTerm != '') {
      string = title.getAttribute('data-search-for');
    } else {
      string = title.getAttribute('data-browse');
    }
    if (!string) return
    string = string.replace(/{term}/g, searchTerm);
    title.innerHTML = string;
  }

  hydrateTitle(count = 0) {
    if (this.searchField()) return
    const title = document.querySelector('h1');
    if (!title) return
    if (count == 0) {
      if (title.hasAttribute('data-base-name')) {
        title.textContent = title.getAttribute('data-base-name');
      }
    } else {
      if (!title.hasAttribute('data-base-name')) {
        title.setAttribute('data-base-name', title.textContent);
      }

      let before = '';
      let after = '';

      const filters = this.filters;
      filters.forEach((filter, filterName) => {
        let traitCount = 1;
        filter.forEach((traitEl, traitName) => {
          let filterDisplay = this.getOption(filterName, traitName).display_name;
          filterDisplay = filterDisplay.replace(/( cards)/gi, '');
          if (filterName == 'Recipients') {
            filterDisplay = filterDisplay.replace(/(for )/gi, '');
            if (filter.size > 2) {
              if (filter.size == traitCount) {
                after += ` and ${filterDisplay}`
              } else {
                after += after == '' ? ` for ${filterDisplay}` : `, ${filterDisplay}`;
              }
            } else {
              after += after == '' ? ` for ${filterDisplay}` : ` and ${filterDisplay}`;
            }
          } else {
            before += `${filterDisplay} `;
          }
          traitCount++
        })
      })
      title.textContent = `${before}${title.getAttribute('data-base-name')}${after}`;
      document.title = `${before}${title.getAttribute('data-base-name')}${after} | American Greetings`;
    }
  }


  initBlock(el) {
    if (el.classList.contains('--init')) return
    el.classList.add('--init');
    const listContainer = el.querySelector('.filter__list-wrapper');
    const showMore = el.querySelector('.filters__show-more');
    listContainer && Utility.addResizeObserver(listContainer, null, '--list-height');
    if (showMore) {
      const showMoreHandler = (trigger) => {
        const el = trigger.closest('fieldset');
        if (!el) return
        const hiddenTraits = el.querySelectorAll('.filter.--may-be-hidden');
        const text = showMore.querySelector('.btn-simple__text');
        if (!hiddenTraits || hiddenTraits.length == 0) return
        if (showMore.getAttribute('aria-expanded') == 'true') {
          showMore.setAttribute("aria-label", `Show ${hiddenTraits.length} more`);
          showMore.setAttribute('aria-expanded', false);
          showMore.classList.remove('--open');
          el.classList.add('--absolute-hidden');
          hiddenTraits.forEach(trait => trait.classList.add('--hidden'))
          this.containersOpened.delete(el.name);
          setTimeout(() => {
            el.classList.remove('--absolute-hidden');
            el.classList.remove('--show-hidden');
          }, 300);
          if (text) {
            text.textContent = text.textContent.replace('less', 'more');
          }
        } else {
          showMore.setAttribute("aria-label", `Show less`);
          showMore.setAttribute('aria-expanded', true);
          this.containersOpened.set(el.name, el);
          showMore.classList.add('--open');
          el.classList.remove('--absolute-hidden');
          el.classList.add('--show-hidden');
          hiddenTraits.forEach(hiddenTrait => {
            setTimeout(() => {
              hiddenTrait.style.top = hiddenTrait.offsetTop + 'px';
              hiddenTrait.classList.remove('--hidden')
            }, 0);
          })
          if (text) {
            text.textContent = text.textContent.replace('more', 'less');
          }
          if (window.app_page?.wasKeyboardEvent) {
            setTimeout(() => {
              hiddenTraits[0].querySelector('input')?.focus();
            }, 300)
          }
        }
      }
      if (this.containersOpened.has(el.name)) {
        showMoreHandler(showMore);
      }
      showMore.addEventListener('click', (e) => { showMoreHandler(e.target) });
    }
  }

  updateBlock(el) {
    if (el.classList.contains('--active-filters')) return
    let countLabel = '';
    if (this.filters.has(el.name)) {
      if (this.filters.get(el.name).size == 1) {
        countLabel = 'has 1 active filter';
      } else if (this.filters.get(el.name).size != 0) {
        countLabel = `has ${this.filters.get(el.name).size} active filters`;
      }
    }
    el.setAttribute('aria-description', countLabel);
  }

  clearBlocks() {
    if (!this.blocks || this.blocks.length == 0) return
    this.blocks.forEach(block => {
      if (block.classList.contains('--active-filters')) return
      block.parentElement.remove();
    })
  }

  clearProducts(grid) {
    const els = grid.querySelectorAll('.product, .products-loop__break');
    if (!els || els.length == 0) return
    els.forEach(el => {
      if (el.querySelector('.lazy-load')?.classList.contains('--loaded')) {
        if (el.hasAttribute('data-id') && !this.imgsLoaded.includes(el.getAttribute('data-id'))) {
          this.imgsLoaded.push(el.getAttribute('data-id'));
        } else if (!this.imgsLoaded.includes(el.id)) {
          this.imgsLoaded.push(el.id);
        }
      }
      if (el.closest("li")) {
        el.closest("li").remove();
      } else {
        el.remove();
      }
    })
  }

  hydrateFilters() {
    this.clearBlocks();
    const facets = this.response.facets;
    facets.forEach(facet => {
      this.renderBlock(facet, this.response.filters)
    });
    this.blocks = this.el.querySelectorAll('.filters__block');
    this.form.initFields();
    this.initBlocks();
    this.initFilters();
  }

  renderBlock(facet, activeFilters) {
    if (this.originalPathname && facet.name == "Product Types") return
    const hasActives = activeFilters instanceof Object && activeFilters.hasOwnProperty(facet.name);
    // Gathering templates
    const containerTemplate = this.el.querySelector('.filter-template');
    const filterTemplate = this.el.querySelector('.trait-template');
    const showMoreTemplate = this.el.querySelector('.show-more-template');
    const holder = this.el.querySelector('.filters__blocks');

    if (!containerTemplate || !filterTemplate || !showMoreTemplate || !holder) return

    // Render filters
    let activeCounter = 0;
    let filterString = '';
    const filterFragment = filterTemplate.content.cloneNode(true);
    let filterInnerClone = Render.stringFromFragment(filterFragment);
    let activesFound = 0;
    let counter = 0;
    let otherFilters = [];


    const renderFilterString = (option, counter) => {
      let filterInner = filterInnerClone;
      let mayBeHidden = counter >= this.maxFiltersShown && facet.options.length + activesFound > this.maxTrigger;
      let isHidden = mayBeHidden && !this.containersOpened.has(facet.name) ? '--hidden' : '';
      let renderedString = Render.interpolateString(filterInner, {
        name: facet.name,
        display: option.display_name,
        label: new DOMParser().parseFromString(option.value, 'text/html').body.textContent,
        selected: option.status == 'selected' ? 'checked' : '',
        path: option.slug,
        count: option.count,
        classes: mayBeHidden ? `--may-be-hidden ${isHidden}` : '',
      })
      if (option.selected == 'selected') {
        activeCounter++;
      }
      return renderedString;
    }

    if (hasActives) {
      facet.options.forEach((option) => {
        if (activeFilters[facet.name].includes(option.value)) {
          filterString += renderFilterString(option, counter);
          counter++;
          activesFound++;
        } else {
          otherFilters.push(option);
        }
      })
    }
    let facetOptionstruncated = hasActives ? otherFilters.slice(0, this.maxFilters - activesFound) : facet.options.slice(0, this.maxFilters);
    facetOptionstruncated.forEach((option) => {
      filterString += renderFilterString(option, counter);
      counter++
    })

    // Render showmore
    let showMoreString = '';
    if (facetOptionstruncated.length + activesFound > this.maxTrigger) {
      showMoreString = Render.interpolateTemplate(showMoreTemplate, {
        remaining: facetOptionstruncated.length + activesFound - this.maxFiltersShown
      }, true);
    }

    // Active counter description
    let countDescription = '';
    if (activeCounter == 1) {
      countDescription = 'has 1 active filter';
    } else if (activeCounter != 0) {
      countDescription = `has ${activeCounter} active filters`;
    }

    const containerHidden = false;

    // Render container
    let renderedBlock = Render.interpolateTemplate(containerTemplate, {
      name: Utility.slugify(facet.name),
      label: facet.name,
      display: facet.display_name,
      hiddenClass: containerHidden ? '--hidden' : '',
      description: countDescription,
      filters: filterString,
      showMore: showMoreString,
    });

    // Inject container
    holder.appendChild(renderedBlock);
  }


  loading() {
    document.querySelector('.main-content-wrapper')?.classList.add('--loading');
    this.loadingEl?.setAttribute('aria-hidden', false);
  }

  complete() {
    document.querySelector('.main-content-wrapper')?.classList.remove('--loading');
    this.loadingEl?.setAttribute('aria-hidden', true);
  }

  hydrateCount() {
    const el = document.querySelector('.products-loop__count');
    if (!el) return
    const totalResults = this.response.total_results_count;
    const perPage = totalResults < this.response.total_results_per_page ? totalResults : this.response.total_results_per_page;
    const page = this.response.page;
    const inputPage = this.el.querySelector('input[type="hidden"][name="page"]');
    if (inputPage) {
      inputPage.value = page;
    }
    const from = 1;
    let to = page * perPage;
    to = to > totalResults ? totalResults : to;
    const items = totalResults == 1 ? `1 item` : `${totalResults} items`;
    let string = totalResults != 0 ? `Showing <strong>${from}-${to}</strong>, of <strong>${items}</strong>` : `No results found`;
    if (totalResults <= this.resultsPerPage) {
      string = `Showing <strong>${items}</strong>`;
    }
    el.innerHTML = string;
  }

  hideLoadMore(duration) {
    if (!this.loadMore) return
    this.loadMore.setAttribute('aria-hidden', true);
    setTimeout(() => { this.loadMore.classList.add('--hidden') }, duration * 1000);
  }

  showLoadMore() {
    if (!this.loadMore) return
    this.loadMore.setAttribute('aria-hidden', false);
    this.loadMore.classList.remove('--hidden');
  }

  hydrateProducts(clear = true) {
    const results = this.response.results;
    const productsHolder = document.querySelector('.products-loop__grid');
    const productTemplate = document.querySelector('.products-loop .product-template, .product-template');
    const badgeTemplate = document.querySelector('.products-loop .product-badge-template, .product-badge-template');
    const cognitiveCount = this.response.total_results_from_cognitive_embeddings;
    if (!productsHolder || !productTemplate || !badgeTemplate) return
    DynamicHeight.start(productsHolder.parentElement);
    if (clear) {
      this.clearProducts(productsHolder);
    }
    const productFragment = productTemplate.content.cloneNode(true);
    let productString = Utility.stringFromFragment(productFragment);
    const badgeFragment = badgeTemplate.content.cloneNode(true);
    let badgeString = Utility.stringFromFragment(badgeFragment);
    let productOutput = ''
    if (window.app_env.page_type == "search" && cognitiveCount) {
      let currentCount = productsHolder.querySelectorAll('.product').length;
      let breakTemplate = document.querySelector('.products-loop-break-template');
      let regularCount = this.response.total_results_count - cognitiveCount;
      results.forEach((result, index) => {
        let realIndex = currentCount + index;
        if (regularCount == realIndex) {
          productOutput += Render.interpolateTemplate(breakTemplate, {
            title: regularCount ? "Here are some similar cards you might like:" : "We didn't find any matches for your search terms, but one of these cards might make you happy!",
          }, true);
        }
        productOutput += this.getRenderedProduct(result, index, productString, badgeString);
      })
    } else {
      results.forEach((result, counter) => {
        productOutput += this.getRenderedProduct(result, counter, productString, badgeString);
      });
    }

    productsHolder.appendChild(Render.fragmentFromString(productOutput));

    let duration = DynamicHeight.end(productsHolder.parentElement);
    if (clear) {
      duration = 0;
    }
    const nextFocus = productsHolder.querySelector('.product.top-anchor');
    if (nextFocus) {
      if (window.app_page?.wasKeyboardEvent) {
        setTimeout(() => nextFocus.querySelector('a')?.focus(), duration * 1000);
      } else if (!Utility.inViewport(nextFocus)) {
        let scrollOverride = false;
        window.app_listeners?.add('scroll', 'detect-scroll', (e) => {
          scrollOverride = true;
          window.app_listeners.remove('scroll', 'detect-scroll');
        })
        setTimeout(() => {
          if (!scrollOverride || clear) {
            Utility.scrollTo(nextFocus, 190)
          }
          window.app_listeners.remove('scroll', 'detect-scroll');
        }, duration * 1000);
      }
      nextFocus.classList.remove('top-anchor');
      this.lastTriggerEvent = null;
    }
    window.app_images?.init(productsHolder);
    if (this.response.total_pages <= this.response.page) {
      this.hideLoadMore(duration);
    } else {
      this.showLoadMore();
    }
  }

  getRenderedProduct(product, counter, productString, badgeString) {
    if (!product) return

    let badges = '';
    let image_url = product.thumb || '';
    let isFavorite = window.app_env?.favorites_ids.includes(product.product_number) || false;

    product.features?.forEach(key => {
      if (!(product.model != "creatacard" && key == "custom-song")) {
        let label = key;
        if (key == "music-choice") {
          key = "music";
          label = "Music Choice";
        }
        if (key == "custom-song") {
          label = "Custom Song";
        }
        badges += Render.interpolateString(badgeString, { key, label });
      }
    });

    let loaded = this.imgsLoaded.includes(String(product.content_id));

    let output = Render.interpolateString(productString, {
      favorite: isFavorite ? '--is-favorite' : "",
      product_label: product.model_label,
      title: product.title,
      model: product.model,
      aria_title: product.aria_title,
      id: product.product_number,
      link: product.url,
      image_url: loaded ? `src="${image_url}"` : `src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="`,
      lazy_image_url: image_url,
      placement_class: counter == 0 ? 'top-anchor' : '',
      image_loaded: loaded ? '--init --loaded --no-animation' : '',
      badges,
    })

    return output
  }
}

export default Filter;